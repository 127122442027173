<template>
  <div class="group-template-th style-box-setting">
    <div class="style-box-setting-header">
      <v-row>
        <v-col cols="4">
          <div class="title-header">
            Gestion des groupes
          </div>
        </v-col>
        <v-col cols="2">
          <v-progress-circular
            class="ml-2"
            v-if="loadingForFetch"
            indeterminate
            color="#704ad1"
          ></v-progress-circular>
        </v-col>
        <v-col cols="3">
          <div class="error-message ml-2 mr-2 mt-3">
            <div v-if="groupeTemplateError">
              <ul v-if="Array.isArray(groupeTemplateError)" class="mb-0">
                <li v-for="(e, index) in groupeTemplateError" :key="index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ groupeTemplateError }}</div>
            </div>
          </div>
        </v-col>
      </v-row>
    </div>
    <v-divider></v-divider>
    <div class="box-content">
      <div class="card-block-filter-setting pt-1 pb-1">
        <div class="right-block-filter"></div>
        <div class="left-block-filter">
          <v-tooltip bottom color="#311B92">
            <template v-slot:activator="{ on, attrs }">
              <div
                class="block-icon-setting text-center mr-1"
                v-on="on"
                v-bind="attrs"
                @click.prevent.stop="openModalAjouterGroupe"
              >
                <font-awesome-icon icon="plus-circle" />
              </div>
            </template>
            <span>Ajouter un groupe</span>
          </v-tooltip>
        </div>
      </div>
    </div>

    <div class="table-gestion-content-gestion-template pt-2">
      <v-data-table
        v-model="selectedTable"
        :headers="fields"
        :items="getGroupeTemplate"
        :items-per-page="getGroupeTemplate.length"
        class="table-gestion-gestion-template"
        hide-default-footer
        disable-sort
        :calculate-widths="false"
        :fixed-header="true"
        hide-action
        :no-data-text="
          getGroupeTemplateDetailsLoading
            ? 'Chargement... Veuillez patienter'
            : `Il n'y a aucune donnée à afficher dans ce tableau.`
        "
      >
        <template v-slot:[`item.name`]="{ item }">
          {{ item.name }}
        </template>
        <template v-slot:[`item.Actions`]="{ item }">
          <td class="custom-cell">
            <v-btn
              small
              class="block-icon-reglement-fac mr-2"
              title="Modifier"
              @click.prevent.stop="handleupdateCurrentGroupe(item)"
            >
              <font-awesome-icon icon="pencil-alt" />
            </v-btn>
            <v-btn
              small
              class="block-icon-reglement-fac mr-2"
              title="Table template"
              @click.prevent.stop="handleTableTemplateClick(item)"
            >
              <font-awesome-icon class icon="list" />
            </v-btn>
            <v-btn
              small
              class="block-icon-reglement-fac mr-2"
              title="Supprimer"
              v-if="item.not_deletable == 0"
              @click.prevent.stop="deleteCurrentGroupe(item)"
            >
              <font-awesome-icon icon="trash" />
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </div>
    <v-dialog
      v-model="addGroupe"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Ajouter un groupe</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('addGroupe')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="addGroupe" lazy-validation>
            <v-text-field
              label="Nom de groupe"
              v-model="name"
              outlined
              required
              dense
              :persistent-placeholder="true"
              :rules="[v => !!v || 'Nom est obligatoire']"
              class="msg-error mt-2"
              validate-on-blur
              color="#704ad1"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <div class="messageError">
          <div v-if="error" class="error-msg">
            <ul
              v-if="Array.isArray(error)"
              class="mb-0"
              style="list-style-type: none"
            >
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="handleSubmitGroupe"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('addGroupe')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="deleteGroupe"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Supprimer un groupe</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('deleteGroupe')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <div class="mt-4 ml-3">
            <p>
              Êtes-vous sur de vouloir supprimer ce groupe
              <strong class="bold-text">
                {{ groupeToDelete ? groupeToDelete.name : '' }}
              </strong>
              ?
            </p>
          </div>
        </v-card-text>

        <div class="messageError">
          <div v-if="error" class="error-msg">
            <ul v-if="Array.isArray(error)">
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            :loading="loading"
            :class="{ loader: loading }"
            :disabled="loading"
            @click.prevent.stop="handleDeleteGroupe"
            >Confirmer
          </v-btn>
          <v-btn outlined @click.prevent="hideModal('deleteGroupe')">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="updateGroupeTemplateModal"
      max-width="820px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Modifier un groupe</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="hideModal('updateGroupeTemplateModal')"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form ref="updateGroupeTemplateModal" lazy-validation>
            <v-text-field
              label="Nom de groupe"
              v-model="name"
              outlined
              required
              dense
              :persistent-placeholder="true"
              :rules="[v => !!v || 'Nom est obligatoire']"
              class="msg-error mt-2"
              validate-on-blur
              color="#704ad1"
            ></v-text-field>
          </v-form>
        </v-card-text>
        <div class="messageError">
          <div v-if="error" class="error-msg">
            <ul
              v-if="Array.isArray(error)"
              class="mb-0"
              style="list-style-type: none"
            >
              <li v-for="(e, index) in error" :key="index">
                {{ e }}
              </li>
            </ul>
            <div v-else>{{ error }}</div>
          </div>
        </div>
        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="handleUpdateGroupe"
            :loading="loading"
            :disabled="loading"
            :class="{ loader: loading }"
          >
            Valider
          </v-btn>
          <v-btn
            outlined
            @click.prevent="hideModal('updateGroupeTemplateModal')"
          >
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Colonne Template  -->
    <v-dialog
      v-model="showTableTemplateModal"
      max-width="1200px"
      scrollable
      hide-overlay
      persistent
    >
      <v-card>
        <v-card-title class="text-h6 title-modal">
          <v-label class="label-header">Table dynamique du template</v-label>
          <v-btn
            class="btn-close-header"
            icon
            @click.prevent="resetTableTemplateModal()"
            title="Fermer"
            color="#704ad1"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider class="line-hr-header"></v-divider>

        <v-card-text class="body-card center-text  mt-2">
          <v-form
            v-if="templateToDisplay && templateToDisplay.Columns !== null"
          >
            <v-row class="mt-2">
              <v-col col xs="2" sm="2" md="2">
                <label for="">Couleur label</label>
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <label for="">Couleur entête</label>
              </v-col>
              <v-col col xs="4" sm="4" md="4"></v-col>
              <v-col col xs="4" sm="4" md="4"></v-col>
            </v-row>
            <v-row>
              <v-col col xs="2" sm="2" md="2">
                <v-input-colorpicker
                  colorpick-eyedropper-active="false"
                  class="color_picker_width"
                  v-model="label"
                />
              </v-col>
              <v-col col xs="2" sm="2" md="2">
                <v-input-colorpicker
                  colorpick-eyedropper-active="false"
                  class="color_picker_width"
                  v-model="color"
                />
              </v-col>
              <v-col col xs="4" sm="4" md="4">
                <v-text-field
                  label="Différence de largeur"
                  type="number"
                  disabled
                  v-model="restSum"
                  :persistent-placeholder="true"
                  outlined
                  color="#704ad1"
                >
                </v-text-field>
              </v-col>
              <v-col col xs="4" sm="4" md="4" class="config-check-ges-template">
                <v-checkbox
                  label="Border"
                  v-model="border"
                  color="#704ad1"
                  :value="true"
                  :true-value="true"
                  :false-value="false"
                  hide-details
                ></v-checkbox>
              </v-col>
            </v-row>
            <div class="row mb-3">
              <div class="col-1"></div>
              <div class="col-1"></div>
              <div class="col-2">
                <span class="bold-text"> Code colonne</span>
              </div>
              <div class="col-3">
                <span class="bold-text"> Nom de colonne</span>
              </div>
              <div class="col-5">
                <span class="bold-text"> Largeur de colonne % </span>
              </div>
            </div>
            <draggable :list="columns">
              <div class="row" v-for="(column, index) in columns" :key="index">
                <div class="col-1">
                  <img src="@/assets/sort.png" class="sort-icon ml-2" />
                </div>
                <p class="m-0" hidden>{{ (column.index = index) }}</p>
                <div class="col-1 config-check-ges-template">
                  <v-checkbox
                    :id="'checkbox-1' + index"
                    name="checkbox-1"
                    v-model="column.check"
                    @change="CalculeRestWidth()"
                    color="#704ad1"
                    :value="true"
                    :true-value="true"
                    :false-value="false"
                    hide-details
                  ></v-checkbox>
                </div>
                <div class="col-2 ">
                  <label>{{ column.column }}</label>
                </div>
                <div class="col-3 ">
                  <v-text-field
                    :persistent-placeholder="true"
                    v-model="column.column_value"
                    outlined
                    required
                    :rules="[v => !!v || 'champ est obligatoire']"
                    validate-on-blur
                    color="#704ad1"
                    class="input-mail-commercial msg-error"
                  >
                  </v-text-field>
                </div>
                <div class="col-5">
                  <v-text-field
                    :persistent-placeholder="true"
                    v-model="column.width"
                    type="number"
                    @change="CalculeRestWidth()"
                    outlined
                    required
                    :rules="[v => !!v || 'champ est obligatoire']"
                    validate-on-blur
                    color="#704ad1"
                    class="input-mail-commercial msg-error"
                  >
                  </v-text-field>
                </div>
                <v-divider></v-divider>
              </div>
            </draggable>
          </v-form>
        </v-card-text>

        <v-divider class="line-hr-footer"></v-divider>
        <v-card-actions class="actions-msg-btn-modal">
          <div class="messageError">
            <div v-if="getDynamicTableErrorsGroupe" class="error-msg">
              <ul
                v-if="Array.isArray(getDynamicTableErrorsGroupe)"
                class="mb-0"
                style="list-style-type: none"
              >
                <li
                  v-for="(e, index) in getDynamicTableErrorsGroupe"
                  :key="index"
                >
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ getDynamicTableErrorsGroupe }}</div>
            </div>
            <div v-else class="error-message">{{ sumError }}</div>
          </div>
          <v-spacer></v-spacer>
          <v-btn
            color="#704ad1"
            outlined
            @click="submitDynamicTableTemplate"
            :loading="getLodingDynamicTableGroupe"
            :disabled="getLodingDynamicTableGroupe"
            :class="{ loader: getLodingDynamicTableGroupe }"
          >
            Valider
          </v-btn>
          <v-btn outlined @click.prevent="resetTableTemplateModal()">
            Annuler
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- End Colonne Template -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import '@kouts/vue-modal/dist/vue-modal.css';
import InputColorPicker from 'vue-native-color-picker';
import draggable from 'vuedraggable';

export default {
  name: 'Gestion-des-catégories',
  data() {
    return {
      name: null,
      error: null,
      loadingForFetch: false,
      loading: false,
      nameToUpdate: null,
      perPage: 10,
      page: 1,
      perPageList: [
        { value: 10, text: 10 },
        { value: 20, text: 20 },
        { value: 50, text: 50 }
      ],
      fields: [
        {
          value: 'name',
          text: 'Nom groupe',
          width: '100% !important'
        },
        { value: 'Actions', text: 'Actions' }
      ],
      groupeToDelete: null,
      groupeToUpdate: null,
      showTableTemplateModal: false,
      templateToDisplay: null,
      columns: [],
      restSum: 0,
      sumError: null,
      color: null,
      idGroupeToSend: null,
      label: null,
      border: false,
      addGroupe: false,
      updateGroupeTemplateModal: false,
      deleteGroupe: false,
      selectedTable: []
    };
  },
  computed: {
    ...mapGetters([
      'getGroupeTemplate',
      'groupeTemplateError',
      'getGroupeTemplateDetailsLoading',
      'getTotalRowGroupeTemplate',
      'checkPermission',
      'getDynamicTableErrorsGroupe',
      'getLodingDynamicTableGroupe'
    ])
  },
  methods: {
    ...mapActions([
      'fetchAllGroupeTemplate',
      'addNewGroupeTemplate',
      'deleteGroupeTemplate',
      'updateGroupeTemplate',
      'updateTableProductsGroupe'
    ]),
    openModalAjouterGroupe() {
      this.addGroupe = true;
    },
    resetModal() {
      this.name = null;
      this.error = null;
      this.loading = false;
    },
    hideModal(ref) {
      this[ref] = false;
      if (ref == 'updateGroupeTemplateModal') {
        this.$refs.updateGroupeTemplateModal.reset();
      }
      if (ref == 'addGroupe') {
        this.$refs.addGroupe.reset();
      }
      this.resetModal();
    },
    async changePerPage() {
      this.loadingForFetch = true;
      this.page = 1;
      this.handleFilter();

      this.loadingForFetch = false;
    },
    async pagination(paginate) {
      this.loadingForFetch = true;
      this.page = paginate;
      this.handleFilter();
      this.loadingForFetch = false;
    },
    async handleFilter() {
      await this.fetchAllGroupeTemplate();
    },
    async handleSubmitGroupe() {
      if (this.$refs.addGroupe.validate()) {
        this.loading = true;
        const response = await this.addNewGroupeTemplate({
          name: this.name
        });
        if (response) {
          this.loading = false;
          this.hideModal('addGroupe');
        } else {
          this.error = this.groupeTemplateError;
          this.loading = false;
        }
      }
    },
    deleteCurrentGroupe(data) {
      this.groupeToDelete = data;
      this.deleteGroupe = true;
    },

    async handleDeleteGroupe() {
      this.loading = true;
      const response = await this.deleteGroupeTemplate(this.groupeToDelete);
      if (response) {
        this.loading = false;
        this.hideModal('deleteGroupe');
        // if (this.getGroupeTemplate.length === 0 && this.page > 1) {
        //   let newPage = this.page - 1;
        //   this.pagination(newPage);
        // }
      } else {
        this.loading = false;
      }
    },
    handleupdateCurrentGroupe(data) {
      this.name = data.name;
      this.groupeToUpdate = data;
      this.updateGroupeTemplateModal = true;
    },
    async handleUpdateGroupe() {
      if (this.$refs.updateGroupeTemplateModal.validate()) {
        this.loading = true;
        const response = await this.updateGroupeTemplate({
          groupe: this.groupeToUpdate,
          name: this.name
        });
        if (response) {
          this.loading = false;
          this.hideModal('updateGroupeTemplateModal');
        } else {
          this.loading = false;
          this.error = this.groupeTemplateError;
        }
      }
    },
    handleTableTemplateClick(groups) {
      this.showTableTemplateModal = true;
      let newArrayToDisplay = [];
      newArrayToDisplay = groups?.columns?.map(item => {
        return { ...item, check: false };
      });
      this.templateToDisplay = newArrayToDisplay;
      this.templateToDisplay?.map(item => {
        this.columns.push({
          id: item.id,
          column: item.column,
          column_value: item.column_value,
          width: item.width,
          index: item.index,
          check: item.blocked === 0 ? true : false
        }),
          (this.color = item.color_bg);
        if (item.border == 1) {
          this.border = true;
        } else {
          this.border = false;
        }
        this.label = item.color;
      });
      this.idGroupeToSend = groups?.id;
      for (let i = 0; i < this?.columns?.length; i++) {
        if (this.columns[i].check === true) {
          this.restSum += parseFloat(groups.columns[i].width);
        }
      }
    },
    async submitDynamicTableTemplate() {
      let bodyFormData = new FormData();
      if (this.border == true) {
        this.border = 1;
      } else {
        this.border = 0;
      }
      let sum = 0;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].check === true) {
          sum += parseFloat(this.columns[i].width);
        }
      }
      if (sum !== 100) {
        this.sumError = 'La somme des largeurs être égale a 100';
      } else {
        bodyFormData.append('group_id', this.idGroupeToSend);
        bodyFormData.append('color_bg', this.color);
        bodyFormData.append('border', this.border);
        bodyFormData.append('color', this.label);
        for (let i = 0; i < this.columns.length; i++) {
          bodyFormData.append(
            'columns[' + i + '][column]',
            this.columns[i].column
          );
          bodyFormData.append(
            'columns[' + i + '][column_value]',
            this.columns[i].column_value
          );
          bodyFormData.append('columns[' + i + '][id]', this.columns[i].id);
          bodyFormData.append(
            'columns[' + i + '][index]',
            this.columns[i].index
          );
          bodyFormData.append(
            'columns[' + i + '][width]',
            this.columns[i].width
          );
          bodyFormData.append(
            'columns[' + i + '][blocked]',
            this.columns[i].check === true ? 0 : 1
          );
        }
        const response = await this.updateTableProductsGroupe(bodyFormData);
        if (response) {
          this.showTableTemplateModal = false;
          await this.handleFilter();
          this.getAllTemplate.map(item1 =>
            item1.templates.map(item2 => {
              if (item2.id === this.idGroupeToSend) {
                this.selectedTemplate.templates.forEach(element => {
                  if (element.id === this.idGroupeToSend) {
                    element.columns = item2.columns;
                    element.color = item2.columns[0].color_bg;
                    element.label = item2.columns[0].color;
                  }
                });
              }
            })
          );
        }
      }
    },
    resetTableTemplateModal() {
      this.columns = [];
      this.sumError = null;
      this.restSum = null;
      this.showTableTemplateModal = false;
    },
    CalculeRestWidth() {
      let sum = 0;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].check === true) {
          sum += parseFloat(this.columns[i].width);
        }
      }
      this.restSum = sum;
    }
  },
  components: {
    'v-input-colorpicker': InputColorPicker,
    draggable
  },
  async mounted() {
    this.loadingForFetch = true;
    this.handleFilter();
    this.loadingForFetch = false;
  }
};
</script>

<style lang="scss" scoped>
.color_picker_width {
  width: 32px;
  height: 32px;
  border: solid 1px;
  margin: auto;
  display: block;
}
.group-template-th {
  height: 100%;
  .tabs-categorie {
    width: 25%;
    margin: 5px 15px;
    .custom-list-group {
      .list-group-item {
        justify-content: space-between;
        color: #515356;
        display: block;
        font-weight: 600;
        .list-item {
          justify-content: space-between;
        }
      }
    }
    .title {
      color: #515356;
      display: block;
      font-weight: 600;
      margin: 0px 0px 10px 0px;
    }
    .categorie-labels {
      display: flex;
      justify-content: space-between;
    }
    .trash-style {
      margin-left: 5px;
      color: #dc3545;
    }
  }
  .table-gestion-content-gestion-template {
    padding: 0px 5px 0px 5px;
    max-height: calc(100vh - 280px) !important;
    height: calc(100vh - 280px) !important;
    margin-bottom: 0;
  }
  .categorie-body {
    display: flex;
    margin: 5px 15px;
    height: calc(100vh - 86px);
    .categorie-details {
      width: 75%;
      .title {
        color: #515356;
        display: block;
        font-weight: 600;
        margin: 5px;
      }
      .input-modal-champ {
        input {
          border-radius: 5px;
        }
      }
      .custom-table-style {
        padding: 5px;
        max-height: calc(100vh - 198px);
        height: calc(100% - 198px);
        width: 100%;
      }
    }
  }
  .ligne-box-setting-header {
    margin-top: 1%;
  }
  .table-gestion-categories-style {
    max-height: calc(100vh - 200px) !important;
    height: calc(100vh - 200px) !important;
    margin-bottom: 0px;
    max-width: 100%;
  }
  .loadingFetch {
    width: 16px;
    height: 16px;
    font-size: 10px;
  }
  .widthTitle {
    width: 180px;
  }
  .loaderPosition {
    display: flex;
    flex-direction: row;
  }
}
.vm-title {
  font-size: 1.25rem;
}
.sort-icon {
  cursor: move;
  height: 20px;
  width: 20px;
}

.button-table-style {
  background-color: #17a2b8;
  font-family: 'Montserrat', sans-serif;
  font-size: 15px;
  color: #ffffff;
  border: none;
}
</style>
<style lang="scss">
.config-check-ges-template {
  .v-input--selection-controls {
    margin-top: 0px !important;
    padding-top: 0px !important;
  }
}
.table-gestion-gestion-template {
  table {
    border-collapse: collapse;
  }
  height: 100% !important;
  max-height: 100% !important;
  .v-data-table__wrapper {
    max-height: calc(100vh - 204px) !important;
    height: calc(100vh - 236px) !important;
    margin-bottom: 0;
    overflow-y: auto !important;
    overflow-x: hidden !important;
  }
  .table {
    table-layout: fixed !important;
    border-collapse: collapse !important;
  }
  th.text-start {
    min-width: 150px !important;
    width: 150px !important;
  }
  .v-data-table-header {
    text-align: center;
    .text-start {
      border: 1px solid #e5e5e5 !important;
      vertical-align: inherit;
      // white-space: nowrap;
      font-size: 11px;
      padding: 0px !important;
      text-align: center !important;
    }
  }
  tbody {
    text-align: center;
    color: #656565;

    td {
      border: 1px solid #e5e5e5 !important;
      vertical-align: inherit;
      font-size: 12px !important;
      font-family: 'Montserrat', sans-serif;
      text-align: center !important;
      padding: 0px !important;
      height: 39px !important;
      .checked-groupe {
        margin-top: 0px !important;
        padding-top: 0px !important;
        float: right !important;
      }
    }
    tr {
      .text-start,
      .custom-cell {
        border: 1px solid #e5e5e5 !important;
        vertical-align: inherit;
        // white-space: nowrap;
        font-size: 11px;
        // padding: 7px 2px !important;
        text-align: center !important;
      }
    }
  }
  thead {
    tr {
      th {
        .checked-global {
          padding-left: 8px;
        }
        span {
          font-weight: 600;
          color: black;
        }
        td {
          .v-input--selection-controls {
            margin-top: 0px !important;
            padding-top: 0px !important;
            float: right !important;
          }
        }
      }
    }
  }
}
.modal-template-groupe-width {
  max-width: 75%;
}
</style>
